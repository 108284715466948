<template>
    <section v-if="initiationId">
        <div id="close-job-vacancy-v2" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="closeModal"/>
                <div class="uk-modal-header uk-flex-inline uk-width-1-1">
                    <img
                        src="/images/job_applicant/close-circle-white.svg"
                        alt="Close Job Vacancy"
                        class="header-icon"
                    />
                    <h2 class="uk-modal-title header-font">Close this Vacancy</h2>
                </div>
                <section class="uk-modal-body space-y-7.5 uk-display-block text-black" style="padding: 20px; color: black;">
                    <div class="uk-width-1-1">
                        <p>
                            Are you sure you want to close this vacancy?<br/>
                            By closing this vacancy it means:<br/>
                        </p>
                        <ul class="uk-margin-remove">
                            <li>The vacancy won’t show up anymore on the App.</li>
                            <li>The worker request’s status will be set to <span class="font-bold">“Completed”</span>.</li>
                            <li>The worker request’s due date will be set to today, <span class="font-bold">{{ closedAt }}</span></li>
                            <li>Your name will be flagged to be the one responsible for this vacancy closure ({{ picName }}).</li>
                        </ul>
                    </div>

                    <div class="uk-width-1-1">
                        <span class="font-semibold">Reminder</span>
                        <div class="uk-width-1-1 reminder-box">
                            <ul class="uk-margin-remove">
                                <li>Don’t forget to process all the applicants in <span class="font-bold">Job Applicant!</span></li>
                                <li>You can always reopen this vacancy by clicking <span class="font-bold">Re-Open Vacancy</span> any time later.</li>
                            </ul>
                        </div>
                    </div>

                    <div class="uk-width-1-1 uk-text-center">
                        <span class="caution-box">
                            <img alt="caution icon" class="caution-icon" src="/images/job_applicant/danger.svg" />

                            Proceed with Caution
                        </span>
                    </div>
                </section>
                <div class="uk-modal-footer justify-between">
                    <button
                        class="uk-button"
                        style="border: 1px solid #EF5350; color: #EF5350; background: #FFF;"
                        @click="closeModal"
                    >
                        Cancel
                    </button>

                    <button
                        class="uk-button text-white"
                        :class="[submitLoading && 'btn-disabled' || 'uk-button-primary']"
                        @click="proceedCloseJob"
                    >
                        <template v-if="submitLoading">
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </template>
                        <template v-else>
                            Yes, I'm Sure
                        </template>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import {mapActions} from 'vuex';
import { notificationDanger } from '@/utils/notification';

export default {
    name: "ModalCloseJobVacancyV2",
    props: {
        initiationId: {
            type: String,
            default: null,
        },
        closedAt: {
            type: String,
            default: null,
        },
        picName: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            submitLoading: false,
        };
    },
    methods: {
        ...mapActions({
            updateStatus: 'initiation/updateStatusV4'
        }),
        async proceedCloseJob() {
            try {
                this.submitLoading = true;

                await this.updateStatus({
                    id: this.initiationId,
                    status: 2,
                    job_status: 4,
                });

                this.$emit("success");
            } catch (e) {
                notificationDanger(e.message || e || 'Something went wrong');
            } finally {
                setTimeout(() => this.submitLoading = false, 750);
                this.closeModal(750);
            }
        },
        closeModal(timeout) {
            const params = [];
            if (timeout > 0) params.push(timeout);

            window.UIkit.modal("#close-job-vacancy-v2").$destroy(true);
            this.$emit("cancel", ...params);
        },
    },
};
</script>
<style scoped>
.btn-disabled {
    background-color: #979797;
    pointer-events: none;
}

.text-white {
    color: #fff !important;
}

.text-black {
    color: #000 !important;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.header-font {
    font-size: 24px;
    line-height: 1.5;
    margin: 0 16px;
    letter-spacing: -0.2px;
}

.header-icon {
    border-radius: 100%;
    background: #FF0000;
    padding: 1px;
    max-width: 40px;
    height: 40px;
    aspect-ratio: 1/1;
}

.caution-box {
    background: #FEBE1033;
    display: inline-flex;
    align-items: center;
    width: fit-content;
    padding: 5px 13px;
    font-weight: 600;
}

.caution-icon {
    padding: 0.5px;
    max-width: 20px;
    height: 20px;
    margin-right: 7px;
    aspect-ratio: 1/1;
}

.reminder-box {
    background: #F8F7F7;
    padding: 10px 8px;
}

section.uk-modal-body > * + * {
    margin-top: 1.875rem !important;
}

</style>
